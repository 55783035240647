import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import OutsideClickHandler from 'react-outside-click-handler'
import { format } from 'date-fns'
import axios from 'axios'
import Select from 'react-select'
import { map, keyBy } from 'lodash'
import styles from '../Profile.module.css'
import InputLabel from '../../InputLabel/InputLabel'
import DatePicker from '../../DatePicker/DatePicker'
import { setUserInfoSession } from '../../../common'
import Documents from '../../ProfileDocument'
import checkImage from '../../../public/images/check.png'
import { ThemeContext } from '../../../Context/ThemeContext'
import { menaList, southAsianList } from '../../../constants'
import Document from '../../ProfileDocument/NewProfileDocument/index'

const customStyle = () => (
  {
    container: provided => ({
      ...provided,
      backgroundColor: '#efeff1',
      color: 'black',
      width: '100%',
      // top: '50%',
      borderRadius: '8px',
      zIndex: 10
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: isSelected || isFocused ? 'black' : 'black', // setting
        backgroundColor: isSelected || isFocused ? '#EFEFF1' : 'white',

        ':active': {
          ...styles[':active'],
          backgroundColor: 'white'
        }
      }
    },
    singleValue: styles => ({
      ...styles,
      color: '#1e1f24'
    }),
    indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
    control: base => ({
      ...base,
      borderWidth: 0,
      borderRadius: 8,
      height: 48,
      fontSize: 14,
      color: '#9096a8',
      backgroundColor: '#EFEFF1',
      border: 0,
      boxShadow: 'none',
      '&:hover': {
        border: 0
      }
    }),
    menu: base => ({
      ...base,
      borderRadius: 8
    })
  })

const errorCustomStyle = () => (
  {
    container: provided => ({
      ...provided,
      backgroundColor: '#efeff1',
      color: 'black',
      width: '100%',
      // top: '50%',
      borderRadius: '8px',
      zIndex: 10
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        color: isSelected || isFocused ? 'black' : 'black', // setting
        backgroundColor: isSelected || isFocused ? '#EFEFF1' : 'white',

        ':active': {
          ...styles[':active'],
          backgroundColor: 'white'
        }
      }
    },
    singleValue: styles => ({
      ...styles,
      color: '#1e1f24'
    }),
    indicatorSeparator: provided => ({ ...provided, visibility: 'hidden' }),
    control: base => ({
      ...base,
      borderWidth: 0,
      borderRadius: 8,
      height: 48,
      fontSize: 14,
      color: '#9096a8',
      backgroundColor: '#EFEFF1',
      border: '1px solid #e61446',
      boxShadow: 'none',
      '&:hover': {
        border: 0
      }
    }),
    menu: base => ({
      ...base,
      borderRadius: 8
    })
  })

const KycFormUpdate = props => {
  const { profileData } = props
  const fullname = profileData.middleName === '' ? profileData.firstName : `${profileData.firstName} ${profileData.middleName}`
  const [firstName, setFirstName] = useState(profileData.firstName ? fullname : '')
  const [firstNameCheck, setFirstNameCheck] = useState(false)
  const [lastName, setLastName] = useState(profileData.lastName ? profileData.lastName : '')
  const [lastNameCheck, setLastNameCheck] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [dateOfBirth, setDateOfBirth] = useState(profileData.dob && profileData.dob !== '--' ? format(new Date(profileData.dob), 'yyyy-MM-dd') : '')
  const [isDobRight, setIsDobRight] = useState(true)
  const [dobErrorMessage, setDobErrorMessage] = useState('')
  const [addressLine1, setAddressLine1] = useState(profileData.addressLine1 && profileData.addressLine1 !== 'x' ? profileData.addressLine1 : '')
  const [addressLine1Check, setAddressLine1Check] = useState(false)
  const [addressLine2, setAddressLine2] = useState(profileData.addressLine2 && profileData.addressLine2 !== 'x' ? profileData.addressLine2 : '')
  const [addressLine2Check, setAddressLine2Check] = useState(false)
  const [address1Error, setaddress1Error] = useState('')
  const [address2Error, setaddress2Error] = useState('')
  const [city, setCity] = useState(profileData.city ? profileData.city : '')
  const [zipCode, setZipCode] = useState(profileData.zipPostalCode ? profileData.zipPostalCode : '')
  const [state, setState] = useState(profileData.stateProvince ? profileData.stateProvince : '')
  const [zipCodeCheck, setZipCodeCheck] = useState(false)
  const [cityError, setcityError] = useState('')
  const [stateError, setStateError] = useState('')
  const [zipcodeError, setzipcodeError] = useState('')
  const [cityCheck, setcityCheck] = useState(false)
  const [stateCheck, setstateCheck] = useState(false)
  const [countryOptions, setCountryOptions] = useState([])
  const [country, setCountry] = useState('')
  const [CountryValue, setCountryValue] = useState('')
  const [CountryError, setCountryError] = useState('')
  const [countryCheck, setCountryCheck] = useState(false)
  const [buttonClick, setButtonClick] = useState(false)
  const [showDocUpload, setShowDocUpload] = useState(false)
  // const [showSelection, setshowSelection] = useState(false)
  const [selectDocUpload, setSelectDocUpload] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
	const { partnerCode } = useContext(ThemeContext)

  useEffect(() => {
    // console.log('country', props)
    // debugger
    // const countryID = profileData['country']
    let countryFilterData=[];
    if (props.countryList && props.countryList.length > 0) {
    //   console.log('inside')
    //   let opt = [...new Set(map(props.countryList, ({ id, name }) => {
    //     return { label: name, value: id }
    //   }))]
    //   debugger
    //   const [val] = props.countryList.filter(item => item.code3 === profileData['country'])
    //   setCountry(val ? val.id : '')
    //   setCountryValue({
    //     value: countryID,
    //     label: val ? val.name : ''
    //   })
    //   setCountryOptions(opt)

      props.countryList.map(item => {
        if(item){
        let temp = {
          label: item.countryName,
          value: item.country
        }
        // //  //set countrylist
        // // if(partnerCode && partnerCode ==='ARCCAPITAL' && profileData['country']){
        // //   //all mena country
        // //   if(southAsianList.includes(temp.value))
        // //     countryFilterData.push(temp)
        // // }
        // // else
        //  if(menaList.includes(profileData['country'])){
        //   //all mena country
        //   if(menaList.includes(temp.value))
        //     countryFilterData.push(temp)
        // }else{
        //   //all list
        //   countryFilterData.push(temp)
        // }

        if(temp.value === profileData['country']){
          setCountry(temp.value)
          setCountryValue({
            value: temp.value,
            label: temp.label
          })
        }
        countryFilterData.push(temp)
      }
      })
      setCountryOptions(countryFilterData)

    }
  }, [profileData, props.countryList])

  const toggleCalender = () => {
    setShowCalendar(!showCalendar)
  }

  const onOutsideClick = () => {
    setShowCalendar(false)
  }

  const formatDateDisplay = date => {
    if (typeof date === 'string') {
      return date
    }
    return format(date, 'yyyy-MM-dd')
  }

  const onHandleDate = date => {
    if (!validateAge(date)) {
      setDobErrorMessage('User must be 18 years or older')
      setIsDobRight(false)
    } else {
      setDobErrorMessage('')
      setIsDobRight(true)
    }
    setDateOfBirth(date, setShowCalendar(false))
  }

  const validateAge = dob => {
    var today = new Date()
    var birthDate = new Date(dob)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    if (age >= 18) return true
    else return false
  }

  const handleClose = () => {
    props.handleClose()
  }

  const handleSubmit = e => {
    if(!selectDocUpload){
      saveProfileData(true)
    }else{
      setShowDocUpload(true)
    }
    // const update = {
    //   type: 'PERSONAL_INFO',
    //   body: {
    //     firstName: firstName,
    //     lastName: lastName,
    //     dob: dateOfBirth,
    //     address1: addressLine1,
    //     address2: addressLine2,
    //     city: city,
    //     state: state,
    //     postalCode: zipCode,
    //     country: country
    //   }
    // }
    // const url = `${process.env.REACT_APP_BASE_API}/v2/users/update`
    // axios
    //   .post(url, update.body)
    //   .then(function(response) {
    //     if (response.data.code === 200) {
    //       setButtonClick(false)
    //       setUserInfoSession(update.body)
    //       props.KycUpdated(true)
    //
    //
    //       // props.handleClose()
    //       // window.location.reload();
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log('basicError', error)
    //     setButtonClick(false)
    //   })
  }

  const saveProfileData=(action)=>{
    const update = {
      type: 'PERSONAL_INFO',
      body: {
        firstName: firstName,
        lastName: lastName,
        dob: dateOfBirth,
        address1: addressLine1,
        address2: addressLine2,
        city: city,
        state: state,
        postalCode: zipCode,
        country: country
      }
    }
    const url = `${process.env.REACT_APP_BASE_API}/v2/users/update`
    axios
      .post(url, update.body)
      .then(function(response) {
        if (response.data.code === 200) {
          setButtonClick(false)
          setUserInfoSession(update.body)
          props.KycUpdated(true)
          if(action)
            props.handleClose()
          // props.handleClose()
          // window.location.reload();
        }
      })
      .catch(function(error) {
        console.log('basicError', error)
        setButtonClick(false)
      })
  }


  // const ApiCall = () => {
  // 	if (
  // 		dateOfBirth !== '' &&
  // 		isDobRight
  // 	) {
  //     console.log('hi')
  //   }
  // }

  const handleInputBox = e => {
    if (e.target.name === 'firstName') {
      if (firstName.length < 1) {
        setFirstNameCheck(true)
      } else {
        setFirstNameCheck(false)
      }
    }
    //  else if (e.target.name === 'lastName') {
    //   if (lastName.length < 1) {
    //     setLastNameCheck(true)
    //   } else {
    //     setLastNameCheck(false)
    //   }
    // }
  }

  const onChangeHandler = e => {
    if (e.target.name === 'firstName')
      setFirstName((e.target.value).replace(/[^A-Za-z ]/ig, ''))
    else if (e.target.name === 'lastName') {
      const regex = new RegExp('^[a-zA-Z]*[.]{0,1}[a-zA-Z]*$')
      if (regex.test(e.target.value))
        setLastName(e.target.value)
    }
  }

  const updateApiCall = () => {
    if (firstName === '') {
      setFirstNameCheck(true)
    }
    // else if (lastName === '') {
    //   setLastNameCheck(true)
    // }
    else if (dateOfBirth === '') {
      setDobErrorMessage('DOB cannot be empty')
    } else if (addressLine1 === '') {
      setAddressLine1Check(true)
      setaddress1Error('Address1 cannot be empty')
    }
    // else if (addressLine2 === '') {
    //   setAddressLine2Check(true)
    //   setaddress2Error('Address2 cannot be empty')
    // }
    else if (zipCode === '') {
      setzipcodeError('Zipcode cannot be empty')
      setZipCodeCheck(true)
    } else if (city === '') {
      setcityCheck(true)
      setcityError('City cannot be empty')
    } else if (state === '') {
      setstateCheck(true)
      setStateError('State cannot be empty')
    } 
    else if (country === '') {
      setCountryError('Country cannot be empty')
      setCountryCheck(true)
    } 
    else if (isDobRight === false) {
      setDobErrorMessage('User must be 18 years or older')
    } else {
      handleSubmit()
      setFirstNameCheck(false)
      setLastNameCheck(false)
      setAddressLine1Check(false)
      setAddressLine2Check(false)
      setZipCodeCheck(false)
      setzipcodeError('')
      setcityCheck(false)
      setcityError('')
      setstateCheck(false)
      setStateError('')
      setCountryError('')
      setCountryCheck(false)
      setaddress2Error('')
      setaddress1Error('')
      setButtonClick(true)
    }
  }

  const showDoc = (action) => {
    setSelectDocUpload(action)
    // setShowDocUpload(action)
  }

  // const docSelection = () => {
  //   return <div style={{ width: '500px' }} className={styles.profileModalBody}>
  //     <>
  //       <Row className={`p-0 mt-0 ${styles.basicDetail}`}>
  //         <Col className={`p-0 ${styles.modalHeadingText}`}>
  //           KYC Update
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col md={12} style={{ marginTop: '32px' }}>
  //           Do you need to upload KYC document again?
  //         </Col>
  //       </Row>
  //       <Row
  //         style={{ marginTop: '32px', marginBottom: '20px' }}>
  //         <Col md={6} xs={6} className={styles.marginCard}>
  //           <div
  //             // onClick={() =>
  //             //   showDoc(true)
  //             // }
  //             className={
  //               !selectDocUpload ? styles.yes : styles.no
  //             }
  //             style={{
  //               paddingLeft: '20px',
  //               paddingTop: '16px',
  //               paddingBottom: '16px',
  //               paddingRight: '16px',
  //               cursor: 'pointer'
  //             }}>
  //             <Row>
  //               <Col xs={5}>
  //                 <div className={styles.first}>
  //                   Yes
  //                 </div>
  //               </Col>
  //               <Col xs={3}>
  //                 <div
  //                   style={{
  //                     background: !selectDocUpload
  //                       ? '#EFEFF1'
  //                       : '#1E1F24',
  //                     width: '36px',
  //                     height: '36px',
  //                     borderRadius: '50%',
  //                     textAlign: 'center',
  //                     paddingTop: '5px'
  //                   }}>
  //                   {!selectDocUpload ? null : (
  //                     <img src={checkImage} />
  //                   )}
  //                 </div>
  //               </Col>
  //             </Row>
  //           </div>
  //         </Col>
  //         <Col md={6} xs={6}>
  //           <div
  //             // onClick={() =>
  //             //   showDoc(false)
  //             // }
  //             className={
  //               selectDocUpload ? styles.yes : styles.no
  //             }
  //             style={{
  //               paddingLeft: '20px',
  //               paddingTop: '16px',
  //               paddingBottom: '16px',
  //               paddingRight: '16px',
  //               cursor: 'pointer'
  //             }}>
  //             <Row>
  //               <Col xs={5}>
  //                 <div className={styles.first}>No</div>
  //               </Col>
  //               <Col xs={3}>
  //                 <div
  //                   style={{
  //                     background: !selectDocUpload
  //                       ? '#1E1F24'
  //                       : '#EFEFF1',
  //                     width: '36px',
  //                     height: '36px',
  //                     borderRadius: '50%',
  //                     textAlign: 'center',
  //                     paddingTop: '5px'
  //                   }}>
  //                   {!selectDocUpload ? (
  //                     <img src={checkImage} />
  //                   ) : null}
  //                 </div>
  //               </Col>
  //             </Row>
  //           </div>
  //         </Col>
  //       </Row>
  //       {/*{showDocUpload ? <Documents setShowSuccessView={setShowSuccessView} /> : null}*/}
  //     </>
  //   </div>
  // }

  const showSuccessView = () => {
    return <div style={{ width: '400px' }} className={styles.profileModalBody}>
      <Row className={`p-0 mt-0 ${styles.basicDetail}`}>
        <Col className={`p-0 ${styles.modalHeadingText}`}>
          KYC Update
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ marginTop: '32px' }}>
          KYC documents uploaded successfully.
        </Col>
      </Row>
      <Row>
        <Col md={6} style={{ marginTop: '32px' }}>
          <Button
            className={`${styles.editSaveButton} themeButton`}
            onClick={handleClose}
            style={{ backgroundColor: props.buttonColor }}>
            {' '}
            Close
          </Button>
        </Col>
      </Row>

    </div>
  }
  const setShowSuccessView = async () => {
    saveProfileData()
    setShowSuccess(true)
  }

  return (
    // showDocUpload ?  :
    showSuccess ? showSuccessView() :
      // showSelection ? docSelection() :
      showDocUpload ? <Document setShowSuccessView={setShowSuccessView} />:
        <div style={{ width: '500px' }} className={styles.profileModalBody}>
          <>
            <Row className={`p-0 mt-0 ${styles.basicDetail}`}>
              <Col className={`p-0 ${styles.modalHeadingText}`}>
                KYC Update
              </Col>
            </Row>
            <Row className={`${styles.inputRowsEdit}`}>
              <Col>
                <div className={`mb-1 ${styles.profileInputLabel}`}>First name</div>
                <input
                  type='text'
                  placeholder='First name'
                  name='firstName'
                  className={
                    firstNameCheck
                      ? styles.profileErrorInputBox
                      : styles.profileInputBox
                  }
                  value={firstName}
                  onBlur={event => handleInputBox(event)}
                  onChange={event => onChangeHandler(event)}
                />
                {firstNameCheck && (
                  <p className={styles.profileErrorText}>FirstName Cannot Be Empty</p>
                )}
              </Col>
            </Row>
            <Row className={`${styles.inputRowsEdit}`}>
              <Col>
                <div className={`mb-1 ${styles.profileInputLabel}`}>Last name (optional)</div>
                <input
                  type='text'
                  placeholder='Last name'
                  name='lastName'
                  className={
                    lastNameCheck
                      ? styles.profileErrorInputBox
                      : styles.profileInputBox
                  }
                  value={lastName}
                  onBlur={event => handleInputBox(event)}
                  onChange={event => onChangeHandler(event)}
                />
                {lastNameCheck && (
                  <p className={styles.profileErrorText}>Field Cannot Be Empty</p>
                )}
              </Col>
            </Row>
            <Row className={`${styles.inputRowsEdit}`}>
              {/* date of birth calender */}
              <Col>
                <Form.Group className='mb-0'>
                  <div className={`mb-1 ${styles.label}`}>Date of Birth</div>
                  <input
                    type='text'
                    name='dateOfBirth'
                    value={formatDateDisplay(dateOfBirth)}
                    readOnly={true}
                    className={
                      dobErrorMessage !== ''
                        ? styles.profileErrorInputBox
                        : styles.profileInputBox
                    }
                    onChange={event => setDateOfBirth(event.target.value)}
                    onClick={toggleCalender}
                    // maxDate={maxDate}
                  />
                  {dobErrorMessage === '' ? (
                    ''
                  ) : (
                    <p className={styles.profileErrorText}>{dobErrorMessage}</p>
                  )}
                </Form.Group>

                {showCalendar ? (
                  <div className={styles.calendar}>
                    <OutsideClickHandler onOutsideClick={onOutsideClick}>
                      <DatePicker
                        onChange={date => {
                          onHandleDate(date)
                          const formatedDate = formatDateDisplay(date)
                          const event = formatedDate
                          setDateOfBirth(event)
                        }}
                        date={new Date()}
                        color='#0061D3'
                      />
                    </OutsideClickHandler>
                  </div>
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Row className={`${styles.inputRowsEdit}`}>
              <Col>
                <div className={`mb-1 ${styles.profileInputLabel}`}>
                  Address Line 1
                </div>
                <input
                  type='text'
                  label='Address Line 1'
                  placeholder='Address Line 1'
                  name='AddressLine1'
                  className={
                    addressLine1Check
                      ? styles.profileErrorInputBox
                      : styles.profileInputBox
                  }
                  value={addressLine1}
                  onBlur={event => handleInputBox(event)}
                  onChange={event => setAddressLine1(event.target.value)}
                />
                <p className={styles.profileErrorText}>{address1Error}</p>
              </Col>
            </Row>
            <Row className={`${styles.inputRowsEdit}`}>
              <Col>
                <div className={`mb-1 ${styles.profileInputLabel}`}>
                  Address Line 2 (optional)
                </div>
                <input
                  label='Address Line 2 (optional)'
                  placeholder='Address Line 2'
                  name='AddressLine2'
                  type='text'
                  value={addressLine2}
                  className={styles.profileInputBox}
                  onBlur={event => handleInputBox(event)}
                  onChange={event => setAddressLine2(event.target.value)}
                />
                <p className={styles.profileErrorText}>{address2Error}</p>
              </Col>
            </Row>
            <Row className={`${styles.inputRowsEdit}`}>
              <Col className={`m-0 ${styles.dropdownInputFont}`}>
                <div className={`mb-1 ${styles.profileInputLabel}`}>
                  Country
                </div>
                <Select
                  placeholder='Country'
                  value={CountryValue}
                  onChange={(value) => {
                    setCountry(value.value)
                    setCountryValue(value)
                  }}
                  options={countryOptions ? countryOptions : ''}
                  styles={CountryError ? errorCustomStyle() : customStyle()}
                  isSearchable={true}
                />
                <p className={styles.profileErrorText}>{CountryError}</p>
              </Col>
            </Row>
            <Row className={`${styles.inputRowsEdit}`}>
              <Col>
                <div className={`mb-1 ${styles.profileInputLabel}`}>
                  Zipcode
                </div>
                <input
                  type='number'
                  label='Zipcode'
                  placeholder='Zipcode'
                  name='Zipcode'
                  value={zipCode}
                  className={
                    zipCodeCheck
                      ? styles.profileErrorInputBox
                      : styles.profileInputBox
                  }
                  onBlur={event => handleInputBox(event)}
                  onChange={event => setZipCode(event.target.value)}
                  onPaste={(e) => {
                    e.preventDefault()
                    return false
                  }} onCopy={(e) => {
                  e.preventDefault()
                  return false
                }}
                />
                <p className={styles.profileErrorText}>{zipcodeError}</p>
              </Col>
            </Row>
            <Row className={`${styles.inputRowsEdit}`}>
              <Col>
                <div className={`mb-1 ${styles.profileInputLabel}`}>City</div>
                <input
                  type='text'
                  label='City'
                  placeholder='City'
                  name='City'
                  className={
                    cityCheck
                      ? styles.profileErrorInputBox
                      : styles.profileInputBox
                  }
                  value={city}
                  onBlur={event => handleInputBox(event)}
                  onChange={event => setCity(event.target.value)}
                />
                <p className={styles.profileErrorText}>{cityError}</p>
              </Col>
            </Row>
            <Row className={`${styles.inputRowsEdit}`}>
              <Col>
                <div className={`mb-1 ${styles.profileInputLabel}`}>State</div>
                <input
                  type='text'
                  label='State'
                  placeholder='State'
                  name='State'
                  className={
                    stateCheck
                      ? styles.profileErrorInputBox
                      : styles.profileInputBox
                  }
                  value={state}
                  onBlur={event => handleInputBox(event)}
                  onChange={event => setState(event.target.value)}
                />
                <p className={styles.profileErrorText}>{stateError}</p>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={{ marginTop: '32px' }}>
                Do you need to upload KYC document again?
              </Col>
            </Row>
            <Row
              className={styles.mobileMarginBottom}>
              <Col md={6} xs={6} className={styles.marginCard}>
                <div
                  onClick={() =>
                    showDoc(true)
                  }
                  className={
                    !selectDocUpload ? styles.yes : styles.no
                  }
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    paddingRight: '16px',
                    cursor: 'pointer'
                  }}>
                  <Row>
                    <Col xs={5}>
                      <div className={styles.first}>
                        Yes
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div
                        style={{
                          background: !selectDocUpload
                            ? '#EFEFF1'
                            : '#1E1F24',
                          width: '36px',
                          height: '36px',
                          borderRadius: '50%',
                          textAlign: 'center',
                          paddingTop: '5px'
                        }}>
                        {!selectDocUpload ? null : (
                          <img src={checkImage} />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6} xs={6}>
                <div
                  onClick={() =>
                    showDoc(false)
                  }
                  className={
                    selectDocUpload ? styles.yes : styles.no
                  }
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    paddingRight: '16px',
                    cursor: 'pointer'
                  }}>
                  <Row>
                    <Col xs={5}>
                      <div className={styles.first}>No</div>
                    </Col>
                    <Col xs={3}>
                      <div
                        style={{
                          background: !selectDocUpload
                            ? '#1E1F24'
                            : '#EFEFF1',
                          width: '36px',
                          height: '36px',
                          borderRadius: '50%',
                          textAlign: 'center',
                          paddingTop: '5px'
                        }}>
                        {!selectDocUpload ? (
                          <img src={checkImage} />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {/* <Row className={styles.kycUpdateButtonContainer}>
              <Col md={6} xs={6}>
                <span onClick={props.handleClose}>Cancel</span>
              </Col>
              <Col md={5} xs={5}>
                <Button
                  disabled={buttonClick}
                  className={`${styles.editSaveButton} themeButton`}
                  onClick={updateApiCall}
                  style={{ backgroundColor: props.buttonColor }}>
                  {' '}
                  Save Changes
                </Button>
              </Col>
            </Row> */}
            <div className={styles.kycUpdateButtonContainer}>
                <span onClick={props.handleClose} className={styles.mobileCancelButton} style={{width:"47%",textAlign:"center"}}>
                  Cancel
                </span>
                <Button
                    disabled={buttonClick}
                    className={`${styles.editSaveButton} themeButton`}
                    onClick={updateApiCall}
                    style={{ backgroundColor: props.buttonColor,width:"47%"}}>
                    {' '}
                    Save Changes
                </Button>
              </div>
          </>
        </div>
  )
}

export default KycFormUpdate
