import React from 'react'
import styles from './IDSelection.module.css'
import { Card, Row, Col } from 'react-bootstrap'
import DL from '../../public/images/DL.svg'
import IdCard from '../../public/images/IDCard.svg'
import Passport from '../../public/images/newOnboarding/PassportOnfido.svg'
import Permit from '../../public/images/Permit.svg'
import Arrow from '../../public/images/ProfileBackArrow.svg'

const selectionData = [
  {
    icon: IdCard,
    title: 'National ID',
    subTitle: 'Front and back',
    code: 'national_identity_card',
    excludeCountryList: ['IND'],
  },
  {
    icon: Passport,
    title: 'Residence permit',
    subTitle: 'Front and back',
    code: 'residence_permit',
    excludeCountryList: ['IND']
  },
  {
    icon: Passport,
    title: 'Driver\'s license',
    subTitle: 'Front and back',
    code: 'driving_licence',
    excludeCountryList: ['IND']
  },
  {
    icon: Passport,
    title: 'Passport',
    subTitle: 'Photo page',
    code: 'passport'
  }
]

const IDSelection = ({ country, onSelection, display }) => {
  const Cards = () => {
    let ui = [];
    selectionData.forEach((doc,idx) => {
      let { countryList, icon, title, subTitle, excludeCountryList } = doc
      let flag = true
      // (countryList && countryList.includes(country)) || countryList === undefined
      if (excludeCountryList && excludeCountryList.includes(country)) {
        flag = false
      } else if (countryList && !countryList.includes(country)) {
        flag = false
      }
      if (flag) {
        ui.push(
          <>
          	<Card className={styles.card} onClick={() => onSelection(doc)}>
						<Card.Body className={styles.cardBody}>
							<Row style={{ margin: '0px' }}>
								<Col style={{ padding: '0px' }} xs={3} md={2}>
									<img alt='icon' className={styles.logoImg} src={icon}></img>
								</Col>
								<Col xs={7} md={8}>
									<div className={styles.cardTitle}>
										{title}
										{/* <img
											alt='icon'
											className={styles.infoLogoImg}
											src={InfoLogo}></img> */}
									</div>
									<div className={styles.cardSubTitle}>{subTitle}</div>
								</Col>
								<Col
									style={{ padding: '0px' }}
									xs={2}
									md={2}
									className={styles.cardAction}>
									<img alt='action' src={Arrow}></img>
								</Col>
							</Row>
						</Card.Body>
					</Card>
          {idx !== selectionData.length - 1 && (
						<div className={styles.orLabel}>OR</div>
					)}
          </>
          )
      }
    })
    if (ui.length)
      return ui
    else
      return <div className={styles.noSupprted}>Registartion not supported for country Of Residence selected</div>
  }

  return (
    <>
      <Row style={{ display }} className="justify-content-center">
        <Col xs={12} >
          <div className={styles.selectionBox}>
            <div className={styles.title}>Verify your identity</div>
            <div className={styles.subTitle}>We need pictures of your Official Identity Document(s) to proceed further.</div>
            {Cards()}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default IDSelection;

